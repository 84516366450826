.navigation__container {
    background-color: var(--nav-color);
    color: white;
}

.navbar-light .navbar-nav .nav-link {
    color: var(--font-color) !important;
}

.navbar-light .navbar-brand {
    color: var(--font-color) !important;
}

.navbar-light .navbar-toggler-icon {
    filter: invert(1);
}

.links a:hover {
    background-color: var(--font-color-hover);
}